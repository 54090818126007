import PermissionsService from "../services/PermissionsService";

const can = function (arg1, arg2) {
    if(arg2 ){
        return !!PermissionsService.getPermission(arg1, arg2);
    }else{
        return !!PermissionsService.getPermissionByAction(arg1);
    }
};

export default can;
