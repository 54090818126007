import LanguageService from "../services/LanguageService";

export default {
    methods: {
        getLocalizationValues(){
            this.$api.get('/api/Localization/getValuesByLang')
                .then(response => {
                    this.$store.commit('changeLocale', response.values);
                })
        },
        getLanguages(){
            this.$api.get('/api/Localization/languages')
                .then(response => {
                    this.$store.commit('changeLanguages', response.languages);
                })
        },
        changeLang(lang){
            LanguageService.saveLang(lang)
            this.getLocalizationValues();
        },
        getLanguage(){
            return LanguageService.getLang()
        }
    },
    computed:{
        getLangComputed(){
            return LanguageService.getLang()
        }
    }
}
