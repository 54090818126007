import Vue from 'vue';

import './services/AppSettingService'
import Vuex from 'vuex';
import App from './App.vue';
import Api from './services/ApiPlugin';
import FileAxios from './services/FileUploadAxios';
import TokenService from "./services/TokenService";
import LanguageService from "./services/LanguageService";
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Snotify from 'vue-snotify';
import router from './router';
import store from './store';
import './mixins/Filters'
import Notifications from "./mixins/Notifications";
import 'vue-snotify/styles/material.css';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import e_imzo from "./services/e_imzo_application";
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import Paginate from 'vuejs-paginate';
import NotificationSignalR from './NotificationSignalR';
import SignalR from './signalR';
import VueChatScroll from 'vue-chat-scroll'
import can from './mixins/can';
import InputMask from 'vue-input-mask';
import NumberFormat from './mixins/numerralFormat'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTheMask from 'vue-the-mask';
import loading from './components/admin/loading'
import JsonExcel from "vue-json-excel";
import MoneyFormat from "@/mixins/MoneyFormat";
import global from "./mixins/global.js";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";



let url = store.state.mainUrl;
Vue.prototype.$_http = url;
Vue.prototype.$_imagePath = url + '/profile/';


axios.interceptors.response.use(res => res, error => {
	if (error.response && error.response.status === 401) {
		TokenService.removeToken();
		TokenService.removeRefreshToken();
		router.push({name: 'login'});
	}
	return Promise.reject(error)
});

Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule]);
});

Vue.config.productionTip = false;
Vue.use(Api);
Vue.use(FileAxios);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Snotify);
Vue.use(e_imzo);
Vue.use(Vuelidate);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueMoment);
Vue.component('paginate', Paginate);
Vue.component('loading', loading);
Vue.component("downloadExcel", JsonExcel);
Vue.component("vSelect", vSelect);
Vue.use(SignalR);
Vue.use(NotificationSignalR);
Vue.use(VueChatScroll);
Vue.use(VueTheMask);
Vue.use(VueSweetalert2);


Vue.mixin(Notifications);
Vue.mixin(NumberFormat);
Vue.mixin(MoneyFormat);
Vue.mixin(global);
Vue.mixin({methods: {can}});
Vue.component('input-mask', InputMask);



new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
