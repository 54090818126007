import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

//let server = 'http://185.183.243.67:7102/chat-hub';
let server = 'http://localhost:7102/chat-hub';

export default {
    install (Vue){
        /*Vue.prototype.$signalR = new HubConnectionBuilder()
            .withUrl(server)
            .configureLogging(LogLevel.Information)
            .build();

        let startedPromise = null;
        function start () {
            startedPromise =  Vue.prototype.$signalR.start().catch(err => {
                console.error('Failed to connect with hub', err);
                return new Promise((resolve, reject) =>
                    setTimeout(() => start().then(resolve).catch(reject), 5000))
            });
            return startedPromise
        }
        Vue.prototype.$signalR.onclose(() => start());

        start()*/
    }
}
