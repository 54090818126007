<template>
  <div class="lang__selection">
    <div class="selct_un">
      <select v-model="lang">
        <option :value="language.key" v-for="language in $store.getters.getLanguages" :key="language.key">
          {{ language.title }}
        </option>
      </select>
      <span class="selct_un_down">
				<img src="/img/downk.png">
			</span>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from './../../mixins/Localization'

export default {
  name: "language",
  mixins: [LocalizationMixin],
  data() {
    return {
      lang: ''
    }
  },
  created() {
    this.lang = this.getLangComputed
  },
  watch: {
    lang(val) {
      this.$store.commit('changeLanguage', val);
      this.changeLang(this.lang)
    }
  }
}
</script>

<style>

.lang__selection {
  width: 110px;
  position: absolute;
  top: 10px;
  right: 100px;
}

.selct_un_down {
  right: -2px;
}
</style>
