import Vue from 'vue'
import Router from 'vue-router';
import AdminLayout from "./layouts/AdminLayout";
import FrontLayout from "./layouts/FrontLayout";
import TokenService from "./services/TokenService";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            component: () => import('./views/front/main/index'),
            name: 'index',
            meta: {
                public: true
            },
        },
        {
            path: '/take-care-of-parents',
            component: () => import('./views/front/main/singleNews-page'),
            name: 'take-care-of-parents',
            meta: {
                public: true
            },

        },
        {
            path: '/uzcard-delivery',
            component: () => import('./views/front/main/singleNews-2-page'),
            name: 'uzcard-delivery',
            meta: {
                public: true
            },
        },
        {
            path: '/football-uzcard',
            component: () => import('./views/front/main/singleNews-3-page'),
            name: 'football-uzcard',
            meta: {
                public: true
            },
        },
        {
            path: '/aviatickets-with-discount',
            component: () => import('./views/front/main/singleNews-4-page'),
            name: 'aviatickets-with-discount',
            meta: {
                public: true
            },

        },
        {
            path: '/faq',
            component: () => import('./views/front/main/faq'),
            name: 'faq',
            meta: {
                public: true
            },

        },
        {
            path: '/',
            component: AdminLayout,
            children: [
                {
                    path: '/dashboard',
                    component: () => import('./views/admin/dashboard/index'),
                    name: 'dashboard'
                },
                {
                    path: 'statistics',
                    component: () => import('./views/admin/statistics/index'),
                    name: 'statistics'
                },
                {
                    path: 'chat',
                    component: () => import('./views/admin/chat/index'),
                    name: 'chat'
                },
                {
                    path: 'settings',
                    component: () => import('./views/admin/settings/index'),
                    name: 'settings'
                },
                {
                    path: 'template-list',
                    component: () => import('./views/admin/templates/list-page'),
                    name: 'list-page'
                },
                {
                    path: 'template-edit',
                    component: () => import('./views/admin/templates/adding-page'),
                    name: 'adding-page'
                },
                {
                    path: 'document-form/:id?',
                    component: () => import('./views/admin/document/create-page'),
                    name: 'document-form'
                },
                // {
                //     path: 'pos-history',
                //     component: () => import('./views/admin/postcards/poshistory'),
                //     name: 'pos-history'
                // },
                // {
                //     path: 'pos-report',
                //     component: () => import('./views/admin/postcards/posreport'),
                //     name: 'posreport'
                // },
                {
                    path: 'corp-history',
                    component: () => import('./views/admin/postcards/corphistory'),
                    name: 'corp-history'
                },
                {
                    path: 'my-posterminals',
                    component: () => import('./views/admin/postcards/myposterminals'),
                    name: 'my-posterminals'
                },
                {
                    path: 'my-corpcards',
                    component: () => import('./views/admin/postcards/mycorpcards'),
                    name: 'my-corpcards'
                },
                {
                    path: 'doc-view/:id',
                    component: () => import('./views/admin/document/view/view'),
                    name: 'doc-view'
                },
                {
                    path: 'inbox-list',
                    component: () => import('./views/admin/document/inbox/inbox-list'),
                    name: 'inbox-list'
                },
                {
                    path: 'inbox-agent-list',
                    component: () => import('./views/admin/document/agent/inbox-list'),
                    name: 'inbox-agent-list'
                },
                {
                    path: 'outbox-list',
                    component: () => import('./views/admin/document/outbox/outbox-list'),
                    name: 'outbox-list'
                },
                {
                    path: 'draft-list',
                    component: () => import('./views/admin/document/draft/draft-list'),
                    name: 'draft-list'
                },
                {
                    path: 'archive-list',
                    component: () => import('./views/admin/document/archive/archive-list'),
                    name: 'archive-list'
                },
                {
                    path: 'bin-list',
                    component: () => import('./views/admin/document/bin/bin-list'),
                    name: 'bin-list'
                },
                {
                    path: 'my-counterparties',
                    component: () => import('./views/admin/counterparties/mycounterparties'),
                    name: 'my-counterparties'
                },
                {
                    path: 'my-applications',
                    component: () => import('./views/admin/counterparties/myapplications'),
                    name: 'my-applications'
                },
                {
                    path: 'invite-me',
                    component: () => import('./views/admin/counterparties/inviteme'),
                    name: 'invite-me'
                },
                {
                    path: 'rejected',
                    component: () => import('./views/admin/counterparties/rejected'),
                    name: 'rejected'
                },
                {
                    path: 'add-agent',
                    component: () => import('./views/admin/counterparties/add-agent'),
                    name: 'add-agent'
                },
                {
                    path: 'blocked',
                    component: () => import('./views/admin/counterparties/blocked'),
                    name: 'blocked'
                },
                {
                    path: 'newmodal',
                    component: () => import('./components/admin/modals/newmodal'),
                    name: 'newmodal'
                },
                {
                    path: 'newmodal2',
                    component: () => import('./components/admin/modals/newmodal2'),
                    name: 'newmodal2'
                },
                {
                    path: 'agreement',
                    component: () => import('./views/admin/agreement/index'),
                    name: 'agreement'
                },
                {
                    path: 'agreed',
                    component: () => import('./views/admin/agreed/index'),
                    name: 'agreed'
                },
                {
                    path: 'agreement-view/:id',
                    component: () => import('./views/admin/document/view/new-view'),
                    name: 'agreement-view'
                },
                // {
                //     path: 'my-terminals',
                //     component: () => import('./views/admin/postcards/newTemplates/myTerminals'),
                //     name: 'my-terminals'
                // },
                // {
                //     path: 'products',
                //     component: () => import('./views/admin/postcards/newTemplates/products'),
                //     name: 'products'
                // },
                {
                    path: 'product-infos/:id',
                    component: () => import('./views/admin/postcards/newTemplates/productInfo'),
                    name: 'productInfos'
                },
                {
                    path: 'info-transaction/:id',
                    component: () => import('./views/admin/postcards/newTemplates/infoTran'),
                    name: 'info-transaction'
                },
                // {
                //     path: 'transaction-invoices',
                //     component: () => import('./views/admin/postcards/newTemplates/transactionInvoices'),
                //     name: 'transaction-invoices'
                // },
                {
                    path: '/excel-log',
                    component: () => import('./views/admin/document/log/excel-log'),
                    name: 'excel-log'
                },
                {
                    path: '/excel-log-new',
                    component: () => import('./views/admin/document/log/excel-log-new'),
                    name: 'excel-log-new'
                },
                {
                    path: '/multi-sign-log',
                    component: () => import('./views/admin/document/log/multi-sign-log'),
                    name: 'multi-sign-log'
                },
                {
                    path: 'new-page-2',
                    component: () => import('./views/admin/newPage/newpage-2'),
                    name: 'new-page-2'
                },
                {
                    path: "/air-report",
                    component: () => import('./views/admin/air/index'),
                    name: 'air-report'

                },
                {
                    path: "/all-documents",
                    component: () => import('./views/admin/allDocuments/allDocuments'),
                    name: 'all-documents'

                },
                {
                    path: "/my-corp-cards",
                    component: () => import('./views/admin/corpCards/myCards'),
                    name: 'my-corp-cards'

                },
                {
                    path: "/corp-card-tran-history",
                    component: () => import('./views/admin/corpCards/historyTransactions'),
                    name: 'my-corp-cards'

                },
                {
                    path: "/corp-card-limit",
                    component: () => import('./views/admin/corpCards/controlLimit'),
                    name: 'corp-card-limit'
                },
                {
                    path: "/no-permission",
                    component: () => import('./views/admin/common/noPermission'),
                    name: 'no-permission'
                },
                {
                    path: "/tasnifGetWithoutVat",
                    component: () => import('./views/admin/catalogTaxCommittee/tasnifGetWithoutVat'),
                    name: 'tasnifGetWithoutVat'
                },
                {
                    path: "/tasnifGetNotConsidered",
                    component: () => import('./views/admin/catalogTaxCommittee/tasnifGetNotConsidered'),
                    name: 'tasnifGetNotConsidered'
                },
                {
                    path: "/tasnifGetRefinanced",
                    component: () => import('./views/admin/catalogTaxCommittee/tasnifGetRefinanced'),
                    name: 'tasnifGetRefinanced'
                },
                {
                    path: "/getZeroVatServices",
                    component: () => import('./views/admin/catalogTaxCommittee/getZeroVatServices'),
                    name: 'getZeroVatServices'
                },
                {
                    path: "/getWithoutVatCompanies",
                    component: () => import('./views/admin/catalogTaxCommittee/getWithoutVatCompanies'),
                    name: 'getWithoutVatCompanies'
                },
                {
                    path: "/getZeroVatCompanies",
                    component: () => import('./views/admin/catalogTaxCommittee/getZeroVatCompanies'),
                    name: 'getZeroVatCompanies'
                },
            ]
        },
        {
            path: '/auth-login',
            component: () => import('./views/front/authorization/authorization-page'),
            name: 'auth-login',
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            },
        },
        {
            path: '/registration',
            component: () => import('./views/front/authorization/registration-page'),
            name: 'registration',
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            },
        },
        {
            path: '/auth-inn',
            component: () => import('./views/front/authorization/inn-page'),
            name: 'auth-inn',
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            },
        },
        {
            path: '/auth-by-token',
            component: () => import('./views/front/authorization/authorization-by-token'),
            name: 'auth-by-token',
            meta: {
                public: true,
                //onlyWhenLoggedOut: false
            },
        },
        {
            path: '/document-qr/:id',
            component: () => import('./views/front/document/view'),
            name: 'document-qr',
            meta: {
                public: true,
            },
        },
        {
            path: '/choose-organization',
            component: () => import('./views/front/authorization/choose-organization'),
            name: 'choose-organization',
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            },
        },
        {
            path: '/users',
            component: () => import('./views/admin/users/users'),
            name: 'users'
        },
        {
            path: '/air',
            component: FrontLayout,
            meta: {
                public: true
            },
            children: [
                {
                    path: 'active-booking/:bookingId',
                    component: () => import('./views/front/air/active-booking'),
                    meta: {
                        public: true
                    },
                },
                {
                    path: 'booking/:bookingId',
                    component: () => import('./views/front/air/booking'),
                    meta: {
                        public: true
                    },
                }
            ],

        }
    ]
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
    let hasToken = !!TokenService.getToken();

    if (!isPublic && !hasToken) {
        return next({
            name: 'auth-inn'
        })
    }

    if (hasToken && onlyWhenLoggedOut) {
        return next({
            name: 'dashboard'
        })
    }

    next();
});

export default router;
