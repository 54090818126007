<template>
    <div>
        <Header/>
        <!--aside-->
        <Aside/>
        <!--main section here-->
        <section class="main_section1">
            <router-view/>
            <loading v-if="$store.state.loading" class="loader" />

        </section>
    </div>
</template>

<script>
	import Header from "../components/admin/Header";
	import Aside from "../components/admin/Aside";
    import Loading from "@/components/admin/loading";

	export default {
		name: "AdminLayout",
		components: {Loading, Aside, Header}
	}
</script>

<style scoped>

</style>
