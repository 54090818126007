import axios from "axios";
import TokenService from "./TokenService";

window.axios = axios;

export default {
    install: function (Vue, options) {
        //let baseURL = "https://edo.uzcardtrade.uz/api/";
        //let baseURL = "http://127.0.0.1:7100/api/";
        let baseURL = Vue.prototype.$_http;
        // let baseURL = "http://192.168.45.54:7100/api/";
        //let baseURL = "http://185.74.5.141:7100/api/"
        axios.defaults.baseURL = baseURL;

        Vue.prototype.$fileAxios = {
            send(requestType, apiRoute, params = {}, data = null) {
                this.setToken();
                return new Promise((resolve, reject) => {
                    axios[requestType](baseURL + apiRoute, params ? params : [], data)
                        .then(res => {
                            resolve(res.data)
                        })
                        .catch(err => {
                            this.errorHandler(err);
                            reject(err);
                        })
                })
            },
            get(apiRoute, params = null) {
                return this.send('get', apiRoute, params);
            },
            post(apiRoute, data = null) {
                return this.send('post', apiRoute, data);
            },
            put(apiRoute, data = null) {
                return this.send('put', apiRoute, data);
            },
            patch(apiRoute, data = null) {
                return this.send('patch', apiRoute, data);
            },
            delete(apiRoute, params = null) {
                return this.send('delete', apiRoute, params);
            },
            errorHandler(err) {
            },
            setToken() {
                axios.defaults.headers.common = {
                    //'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    //'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + TokenService.getToken(),
                };
            }

        };

    }
};
