<template>
    <div>
        <HeaderTop />
        <HeaderLink />
        <router-view/>
        <Footer />
    </div>
</template>

<script>
    import Footer from "../layouts/components/Footer";
    import HeaderTop from './components/HeaderTop';
    import HeaderLink from './components/HeaderLink';
    export default {
        name: "FrontLayout",
        components: {Footer, HeaderTop, HeaderLink},
        data() {
            return {}
        },
        methods: {},
        created() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
<style>
@import '../../public/css/landing-page-styles';
</style>