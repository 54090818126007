<template>
    <div class="header-link-view">
        <section class="headerlink">
            <div class="my_container">
                <div class="headerlink_all">
                    <div class="humburger_for_mobile_cpvwwe">
                        <div class="tel-number__mobile">
                            <span>Номер телефона:</span>
                            <a href="tel: +998781508855">(78) 150 88 55</a>
                        </div>
                        <button class="humburger_for_mobile">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="headelink_ul_cover">
                        <ul class="headelink_ul">
                            <li class="headelink_ul_li">
                                <a class="headelink_ul_li_aa go_to" href="#theader_id">Возможности</a>
                            </li>
<!--                            <li class="headelink_ul_li">-->
<!--                                <a class="headelink_ul_li_aa go_to" href="#work-info">Как это работает?</a>-->
<!--                            </li>-->
                            <li class="headelink_ul_li">
                                <a class="headelink_ul_li_aa go_to" href="#payment">Тарифы и оплата</a>
                            </li>
                            <li class="headelink_ul_li">
                                <a class="headelink_ul_li_aa go_to" href="#news">Новости</a>
                            </li>
                          <li class="headelink_ul_li">
                            <router-link to="/faq" tag="a" class="headelink_ul_li_aa go_to">FAQ</router-link>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "HeaderLink"
    }
</script>

<style scoped>

</style>