

export default {
    methods: {
        deleteSpaces(str) {
            if(str != null && str != '') {
                let resultVal = '';
                for (let i = 0; i < str.length; i++) {
                    if (str[i] !== ' ')
                        resultVal += str[i];
                }
                return resultVal;
            }
        },
        formatMoney (value, place = ' ', thousandLength = 15) {
            if (!value)
                return '';
            const val = value.toString().replace(/,/gi, '.');
            let whole = '';
            let decimal = '';
            let resultVal = '';
            let dotsCount = 0;
            for (let i = 0; i < val.length; i++) {
                if (val[i] === '.') dotsCount++;
            }
            if (dotsCount > 0) {
                let arr = val.split('.');
                whole = arr[0];
                decimal = arr[1].substring(0, thousandLength);
            }
            else if (dotsCount > 1) {
                const lastDotIndex = val.lastIndexOf('.');
                resultVal = val.substring(0, lastDotIndex);
            }
            else {
                whole = val;
                decimal = '';
            }
            if (whole.length > 3) {
                let formattedString = '';
                let numbersCount = 0;
                for (let i = whole.length - 1; i >= 0; i--) {
                    numbersCount++;
                    formattedString += whole[i];
                    if (numbersCount === 3) {
                        formattedString += place;
                        numbersCount = 0
                    }
                }
                whole = formattedString.split('').reverse().join('');
                if (whole[0] === ' ') {
                    whole = whole.substring(1)
                }
            }
            if (dotsCount > 0)
                resultVal = whole + '.' + decimal;
            else {
                resultVal = whole;
            }
            return resultVal;
        },
    }
}
