<template>
  <aside class="myaside myaside_left_0">
    <div class="myaside_all">
      <div class="accordion" id="accordionExample">
        <div class="card" v-if="can('document_edit') || can('document_view')">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false" aria-controls="collapseOne">
                <span>{{ $store.getters.getContentLocale.documents }}</span>
                <span class="myaside_all_hicon">
								<i class="fa fa-angle-down" aria-hidden="true"></i>
							</span>
              </button>
            </h2>
          </div>


          <div

              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample">
            <div class="card-body">
              <div class="myaside_podmenu">
                <router-link
                    v-if="can('document_edit')"
                    to="/document-form"
                >
                  {{ $store.getters.getContentLocale.createdocument }}
                </router-link>
                <router-link to="/all-documents">{{ $store.getters.getContentLocale.alldocuments }}</router-link>
                <router-link to="/inbox-list">{{ $store.getters.getContentLocale.incoming }}</router-link>
                <router-link to="/outbox-list" tag="a">{{ $store.getters.getContentLocale.outgoing }}
                </router-link>
                <router-link to="/draft-list" tag="a">{{ $store.getters.getContentLocale.draft }}
                </router-link>
                <router-link v-if="can('agent_section_view')" to="/inbox-agent-list" tag="a">{{ $store.getters.getContentLocale.confidant }}</router-link>
                <router-link to="/archive-list" tag="a">{{ $store.getters.getContentLocale.archive }}
                </router-link>
                <router-link to="/bin-list" tag="a">{{ $store.getters.getContentLocale.basket }}
                </router-link>
                <!--<router-link to="/agreement" tag="a">
                    {{$store.getters.getContentLocale.requiringagreements}}
                </router-link>-->
                <router-link to="/agreed" tag="a">{{ $store.getters.getContentLocale.agreement }}
                </router-link>
                <router-link :to="{name: 'excel-log'}" tag="a">{{ $store.getters.getContentLocale.excellogs }}
                </router-link>
                <router-link :to="{name: 'excel-log-new'}" tag="a">{{ $store.getters.getContentLocale.importexcel }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="card" v-if="can('chat_view')">
            <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                    <router-link to="/chat" tag="button" class="btn btn-link collapsed" type="button">
                        <span>{{$store.getters.getContentLocale.message}}</span>
                        <span class="notice__icon" v-if="allUnreadMessagesCount">
                            {{allUnreadMessagesCount}}
                        </span>
                    </router-link>
                </h2>
            </div>
        </div>-->
        <div class="card" v-if="can('statistics_view')">
          <div class="card-header">
            <h2 class="mb-0">
              <router-link to="/statistics" tag="button" class="btn btn-link collapsed" type="button">
                <span>{{ $store.getters.getContentLocale.statistics }}</span></router-link>
            </h2>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h2 class="mb-0">
              <router-link to="/settings" tag="button" class="btn btn-link collapsed" type="button">
                <span>{{ $store.getters.getContentLocale.settings }}</span></router-link>
            </h2>
          </div>
        </div>
        <!--<div class="card" v-if="can('network_view')">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        <span>{{$store.getters.getContentLocale.сontractors}}</span>
                        <span class="myaside_all_hicon">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </span>
                    </button>
                </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                 data-parent="#accordionExample">
                <div class="card-body">
                    <div class="myaside_podmenu">
                        <router-link v-if="can('network_edit')"  to="/add-agent" tag="a">
                            {{$store.getters.getContentLocale.addcounterparty}}
                        </router-link>
                        <router-link to="/my-counterparties" tag="a">
                            {{$store.getters.getContentLocale.mycontragents}}
                        </router-link>
                        <router-link to="/my-applications" tag="a">
                            {{$store.getters.getContentLocale.myapplications}}
                        </router-link>
                        <router-link to="/invite-me" tag="a">{{$store.getters.getContentLocale.invitedme}}
                        </router-link>
                        <router-link to="/rejected" tag="a">
                            {{$store.getters.getContentLocale.rejectedapplications}}
                        </router-link>
                        <router-link to="/blocked" tag="a">
                            {{$store.getters.getContentLocale.blockedapplications}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>-->
<!--        <div class="card"-->
<!--             v-if="can('org_transactions_view') || can('org_terminals_view')||-->
<!--                            can('org_products_view') ||-->
<!--                            can('org_transactions_invoices_view')"-->
<!--        >-->
<!--          <div class="card-header" id="headingFour">-->
<!--            <h2 class="mb-0">-->
<!--              <button class="btn btn-link collapsed" type="button" data-toggle="collapse"-->
<!--                      data-target="#collapseFour" aria-expanded="false"-->
<!--                      aria-controls="collapseFour">-->
<!--                <span>{{ $store.getters.getContentLocale.posterminalscorporatecards }}</span>-->
<!--                <span class="myaside_all_hicon">-->
<!--                                    <i class="fa fa-angle-down" aria-hidden="true"></i>-->
<!--                                </span>-->
<!--              </button>-->
<!--            </h2>-->
<!--          </div>-->
<!--          <div id="collapseFour" class="collapse" aria-labelledby="headingThree"-->
<!--               data-parent="#accordionExample">-->
<!--            <div class="card-body">-->
<!--              <div class="myaside_podmenu">-->
<!--                <router-link v-if="can('org_transactions_view')" to="/pos-history" tag="a">-->
<!--                  {{ $store.getters.getContentLocale.posterminaltransactionhistory }}-->
<!--                </router-link>-->
<!--                <router-link v-if="can('org_transactions_view')" to="/pos-report" tag="a">-->
<!--                  {{ $store.getters.getContentLocale.transactionreport }}-->
<!--                </router-link>-->
<!--                <router-link v-if="can('org_terminals_view')" to="/my-terminals" tag="a">-->
<!--                  {{ $store.getters.getContentLocale.myposterminals }}-->
<!--                </router-link>-->
<!--                <router-link v-if="can('org_products_view')" to="/products" tag="a">-->
<!--                  {{ $store.getters.getContentLocale.products }}-->
<!--                </router-link>-->
<!--                <router-link v-if="can('org_transactions_invoices_view')" to="/transaction-invoices"-->
<!--                             tag="a">-->
<!--                  {{ $store.getters.getContentLocale.creatinginvoice }}-->
<!--                </router-link>-->
<!--                &lt;!&ndash;                                                <a href="/my-corpcards">Мои корпоративные карты</a>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="card" v-if="can('airreport')">
          <div class="card-header">
            <h2 class="mb-0">
              <router-link to="/air-report" tag="button" class="btn btn-link collapsed" type="button">
                <span>Отчёты "HAVO YO'LLARI"</span></router-link>
            </h2>
          </div>
        </div>


        <div class="card" v-if="can('corpcard')">
          <div class="card-header" id="heading5">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#collapse5" aria-expanded="false"
                      aria-controls="collapse5">
                <span>{{$store.getters.getContentLocale.corporatecards }}</span>
                <span class="myaside_all_hicon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
              </button>
            </h2>
          </div>
          <div id="collapse5" class="collapse" aria-labelledby="heading5"
               data-parent="#accordionExample">
            <div class="card-body">
              <div class="myaside_podmenu">
                <router-link to="/my-corp-cards" tag="a">
                  {{ $store.getters.getContentLocale.mycards }}
                </router-link>
                <router-link to="/corp-card-limit" tag="a">
                  {{ $store.getters.getContentLocale.managementbylimits }}
                </router-link>
                <router-link to="/corp-card-tran-history" tag="a">
                  {{ $store.getters.getContentLocale.historytransactions }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="heading6">
            <h2 class="mb-0">
              <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#collapse6" aria-expanded="false"
                      aria-controls="collapse6">
                <span>{{$store.getters.getContentLocale.catalogtax }}</span>
                <span class="myaside_all_hicon">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </span>
              </button>
            </h2>
          </div>
          <div id="collapse6" class="collapse" aria-labelledby="heading6"
               data-parent="#accordionExample">
            <div class="card-body">
              <div class="myaside_podmenu">
                <router-link to="/tasnifGetWithoutVat" tag="a">
                  {{$store.getters.getContentLocale.productsserviceswithoutvat}}
                </router-link>
                <router-link to="/getZeroVatServices" tag="a">
                  {{$store.getters.getContentLocale.productsservicesvatzero}}
                </router-link>
                <router-link to="/getWithoutVatCompanies" tag="a">
                  {{$store.getters.getContentLocale.companiessellingwithoutvatorvatzero}}
                </router-link>
                <router-link to="/getZeroVatCompanies" tag="a">
                  {{$store.getters.getContentLocale.businessespurchasingwithoutvatorvatzero}}
                </router-link>
                <router-link to="/tasnifGetNotConsidered" tag="a">
                  {{$store.getters.getContentLocale.productsnottakenintoaccount}}
                </router-link>
                <router-link to="/tasnifGetRefinanced" tag="a">
                  {{$store.getters.getContentLocale.reimbursementservices}}
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Aside",
  data() {
    return {
      allUnreadMessagesCount: null,
    }
  },
  methods: {
    getAllUnreadMessagesCount() {
      this.$api.get('/api/Chat/allUnreadMessagesCount').then(res => {
        if (res.success) {
          this.allUnreadMessagesCount = res.allUnreadMessagesCount;
        }
      }).catch(error => {
      }).finally(final => {

      })
    }
  },
  created() {

    if (this.can("chat_view")) {
      this.getAllUnreadMessagesCount();
    }
  },
  watch: {
    '$store.state.unreadMessageCountChange': function () {
      this.getAllUnreadMessagesCount();
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: unset;
}

.notice__icon {
  font-size: 16px;
  background-color: #fff;
  color: #023972;
  padding: 13px;
}
</style>

