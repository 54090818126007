export default {
    data() {
        return {
        }
    },
    methods: {

    },
    computed: {
        orgId() {
            return localStorage.getItem('organizationId') ? Number(localStorage.getItem('organizationId')) : 0
        },
        currentLang() {
            return this.$store.state.language;
        },
        currentLangCapitalized() {
            return this.currentLang.charAt(0).toUpperCase() + this.currentLang.slice(1)
        },
        localizedName() {
            if (this.currentLang === 'ru') {
                return 'nameRu'
            } else if (this.currentLang === 'uz') {
                return 'nameUz'
            }
            return 'name'
        },
    }

}