const KEY = 'lang';

const LanguageService = {
    getLang() {
        return localStorage.getItem(KEY) ? localStorage.getItem(KEY) : 'ru';
    },
    saveLang(value) {
        localStorage.setItem(KEY, value);
    },
    removeLang() {
        localStorage.removeItem(KEY);
        localStorage.clear();
    }
};

export default LanguageService;