const PermissionsService = {
	setPermissions(permissions) {
		localStorage.setItem('permissions', JSON.stringify(permissions));
	},
	getPermissions() {
		return JSON.parse(localStorage.getItem('permissions') || JSON.stringify([]));
	},
	getPermission(roleName, actionKey) {
		return this.getPermissions().find(item => item.roleName === roleName && item.actionKey === actionKey);
	},
    getPermissionByAction(actionKey) {
        return this.getPermissions().find(item => item.actionKey === actionKey);
    }
};

export default PermissionsService;
