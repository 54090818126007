<template>
    <section class="into_header">
        <div class="header__logo">
            <div class="myaside_humburger">
                <button class="myaside_humburger_btn">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
            </div>
            <router-link :to="{name:'dashboard'}">
                <a class="header_tall_logod" href="#" style="background-size: contain"></a>
            </router-link>
        </div>
        <div class="header_tall">
            <div class="into_header_summa_cover">
                <div class="into_header_summa">
                    <div class="both_same mb-1 mt-1">
                        <span class="both_same_1">{{ $store.getters.getContentLocale.organization }}:</span>
                        <span class="both_same_2">{{ org.shortName }}</span>
                    </div>
                    <div class="both_same mt-1 mb-1">
                        <span class="both_same_1">{{ $store.getters.getContentLocale.balans }}:</span>
                        <span class="both_same_2">
                            <b>{{ balance | financialFormatMoney }}</b>
                         </span>
                    </div>
                    <router-link :to="{name: 'settings', params: {id: this.id = 1}}"
                                 class="both_same mt-1 mb-1 replenish-btn">
                        <span class="both_same_2">
                            <b>{{ $store.getters.getContentLocale.deposit }}</b>
                         </span>
                    </router-link>
                    <router-link v-if="can('document_edit')" :to="{name: 'document-form'}"
                                >
                            <button class="btn btn-primary border-0 mb-1 mt-1" style="background-color: #023972">
                                {{ $store.getters.getContentLocale.createdocument }}
                            </button>
                    </router-link>
                </div>
            </div>
            <div class="header_tall_right">
                <div class="call__block mr-3">
                    <div class="call__block-item">
                        {{ $store.getters.getContentLocale.questionhelper }} <a href="tel:+998781508855">+998 78 150 88 55</a>
                    </div>
                    <span class="choose-operator">{{ $store.getters.getContentLocale.chooseanesfoperatorlink }}: <a
                        :href="$store.getters.getContentLocale.facturalink">{{ $store.getters.getContentLocale.facturalink }}</a></span>
                </div>
                <button class="btn my_2btn position-relative">
                    <!--                    <span class="blue_raund"></span>-->
                    <span class="my_2btn_img">
					<img src="/img/balll.png">
				</span>
                </button>
                <div class="my_user">
                    <div class="my_user_img_cover">
                        <img class="my_user_img"
                             v-bind:src="personalInfo.photo == null ? '/img/avatar.jpg' : $_imagePath + personalInfo.photo">
                    </div>
                    <div class="my_user_down">
                        <div class="trankle_fordown"></div>
                        <!--                        <button class="btn pr_btn">Мои организации</button>-->
                        <button class="btn pr_btn" @click="logout">{{ $store.getters.getContentLocale.logout }}</button>
                    </div>
                </div>
                <language-component class="header__lang-section"></language-component>
            </div>
        </div>
    </section>
</template>

<script>
import LanguageComponent from './../../components/additinals/language'
import router from "../../router";

export default {
    name: "Header",
    components: {
        LanguageComponent
    },
    data() {
        return {
            org: {},
            personalInfo: {},
            balance: 0,
            id: null
        }
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push({name: 'index'})
        },
        getOrgInfo() {
            this.$api.get('/api/Organization/getById').then(res => {
                this.org = res.organization;
                this.$store.commit('setOrgInfo', res.organization);
            });
        },
        getUser() {
            this.$api.get('/api/Auth/getUserInfo').then(res => {
                this.personalInfo = res.data;
            })
        },
        getPaymentInfo() {
            this.$api.get('/api/payment/paymentInfo').then(res => {
                this.balance = res.balance
            })
        },
    },
    created() {
        this.getOrgInfo();
        this.getUser();
        this.getPaymentInfo();
    },
    filters: {
        balanceFormat(val) {
            return new Intl.NumberFormat('ru-RU').format(val)
        }
    }

}
</script>

<style scoped>
.header_tall {
    width: calc(100% - 209px);
}

.call__block-item,
.call__block-item a {
    color: #ff8201;
}

.call__block-item a:hover {
    text-decoration: none;
    color: #c96804;
}

.header_tall_right {
    margin-right: 125px;
}

.header__lang-section {
    top: 16px;
    right: 25px;
}

.selct_un_down {
    right: -32px;
}

.choose-operator {
    font-size: 12px;
    color: #ec0000;
}

.choose-operator a {
    color: #ec0000;
    /*color: #89bf04;*/
}

.choose-operator a:hover {
    color: #c10606;
}

.call__block.mr-3 {
    line-height: 18px;
}

</style>
