import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

let server = 'http://185.183.243.67:7102/notification_hub';
// let server = 'http://185.211.129.21:7102/notification_hub';
//let server = 'http://172.17.9.237:9999/location-hub';
export default {
    install (Vue){
        Vue.prototype.$NotificationsignalR = new HubConnectionBuilder()
            .withUrl(server)
            .configureLogging(LogLevel.Information)
            .build();

        let startedPromise = null;
        function start () {
            startedPromise =  Vue.prototype.$NotificationsignalR.start().catch(err => {
                console.error('Failed to connect with hub', err);
                return new Promise((resolve, reject) =>
                    setTimeout(() => start().then(resolve).catch(reject), 5000))
            });
            return startedPromise
        }
        // Vue.prototype.$NotificationsignalR.onclose(() => start());
        // start()
    }
}
