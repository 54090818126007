import Vuex from 'vuex';
import Vue from 'vue'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mainUrl: 'https://edo.uzcardtrade.uz',
        //mainUrl: 'http://localhost:7100',
        unreadMessageCountChange: false,
        localization:{
            languages: {},
            locale:{}
        },
        highlighted: {
            dates: [
                new Date(),
            ],
        },
        copyDocumentId: null,
        loading: false,
        telegramUserName: 'edouzcard',
        language: 'ru',
        orgInfo: null,
        taxPayerType: {}
    },
    actions: {

    },
    getters: {
        getLocale(state){
            return state.localization.locale;
        },
        getErrorLocale(state){
            return  state.localization.locale.ErrorFront ? state.localization.locale.ErrorFront : {};
        },
        getContentLocale(state, key){
            return state.localization.locale.Content ? state.localization.locale.Content : {};
        },
        getLanguages(state){
            return state.localization.languages;
        },
        getPayment(state) {
            return state.payment
        },
        getTaxPayerType(state) {
            return state.taxPayerType
        }
    },
    modules: {},
    mutations: {
        changeUnreadMessageState(state) {
            state.unreadMessageCountChange = !state.unreadMessageCountChange;
        },
        changeLocale(state, locale){
            state.localization.locale = locale
        },
        changeLanguages(state, languages){
            state.localization.languages = languages
        },
        changeLanguage(state, language) {
            state.language = language
        },
        loader (state, value) {
            state.loading = value
        },
        setOrgInfo(state, value) {
            state.orgInfo = value;
        },
        setTaxPayerType(state, value) {
            state.taxPayerType = value;
        }

    },
    strict: {}
});
