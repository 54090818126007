<template>
    <div class="footer-view">
        <footer class="footer">
            <div class="my_container">
                <div class="footer-block">
                    <div class="footer-block__logo">
                        <img src="../../../public/img/landing-page/img/logo-footer.png">
                        <p>Система документооборота</p>
                    </div>
                    <div class="footer-block__item-links-block">
                        <div class="footer-block__item">
                            <h6>Контакты</h6>
                            <span>Номер телефона:</span>
                            <a href="tel: +998781508855">(78) 150 88 55</a>
                        </div>
                        <div class="footer-block__item">
                            <h6></h6>
                            <span>Эл. почта:</span>
                            <a href="mailto:info@uzcardtrade.uz">info@uzcardtrade.uz</a>
                            <span>Адрес:</span>
                            <a href="" class="disabled">Шайхантохурский район, <br> ул. Абдулла Кодирий 78 дом</a>
                        </div>
                        <div class="footer-block__item">
                            <h6>Мы в соц. сетях</h6>
                            <div class="footer-block__item-social">
                                <a href="https://www.instagram.com/uzcardtrade.uz" class="footer-block__item-social-link">
                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.facebook.com/uzcardtrade.uz" class="footer-block__item-social-link">
                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                                <a href="https://twitter.com/uzcardtrade" class="footer-block__item-social-link">
                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                                <a href="https://t.me/uzcardtradeuz" class="footer-block__item-social-link">
                                    <i class="fa fa-telegram" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="footer-block__item">
<!--                            <h6>Еще</h6>-->
                            <div class="footer-block__item-links">
<!--                                <a href="#" class="footer-block__item-link">О нас</a>-->
<!--                                <a href="#" class="footer-block__item-link">Инструкция</a>-->
                                <a href="#" class="footer-block__item-link" data-toggle="modal" data-target="#oferta-modal">Публичная оферта</a>
                            </div>
                        </div>
                    </div>
                    <div class="footer-block__item">
                        <a href="#theader_id" class="footer_all_right_topsc go_to">
                            <img src="../../../public/img/landing-page/img/icons/top.png">
                        </a>
                    </div>
                </div>
                <div class="row footer__mobile-menu">
                    <div class="footer__mobile-menu__top">
                        <div class="mobile__menu-top__logo">
                            <img src="../../../public/img/landing-page/img/logo-footer.png">
                        </div>
                        <div class="mobile__menu-top__text">
                            <p>Система документооборота</p>
                        </div>
                    </div>
                    <div class="accordion" id="accordionExample2">
                        <div class="card">
                            <div class="card-header" id="contact-collapse">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#contact-collapse-btn" aria-expanded="true" aria-controls="contact-collapse-btn">
                                        <p>Контакты</p>
                                        <p class="footer__menu-link-icon"><i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                    </button>
                                </h2>
                            </div>

                            <div id="contact-collapse-btn" class="collapse" aria-labelledby="contact-collapse" data-parent="#accordionExample2">
                                <div class="card-body">
                                    <div class="mobile__footer-links">
                                        <span>Номер телефона:</span>
                                        <a  href="tel: +998781508855" class="mobile__footer-link">(78) 150 88 55</a>
                                    </div>
                                    <div class="mobile__footer-links">
                                        <span>Эл. почта:</span>
                                        <a href="mailto:info@uzcardtrade.uz" class="mobile__footer-link">info@uzcardtrade.uz</a>
                                    </div>
                                    <div class="mobile__footer-links">
                                        <span>Адрес:</span>
                                        <p class="mobile__footer-link">Шайхантохурский район,
                                            ул. Абдулла Кодирий 78 дом</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="more-collapse">
                                <h2 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="modal" data-target="#oferta-modal">
                                        <p>Публичная оферта</p>
<!--                                        <p class="footer__menu-link-icon"><i class="fa fa-angle-right" aria-hidden="true"></i></p>-->
                                    </button>
                                </h2>
                            </div>

<!--                            <div id="more-collapse-btn" class="collapse" aria-labelledby="more-collapse" data-parent="#accordionExample2">-->
<!--                                <div class="card-body">-->
<!--&lt;!&ndash;                                    <div class="mobile__footer-links">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <a href="#" class="mobile__footer-link">О нас</a>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="mobile__footer-links">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <a href="#" class="mobile__footer-link">Инструкция</a>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                    <div class="mobile__footer-links">-->
<!--                                        <a href="#" class="mobile__footer-link" data-toggle="modal" data-target="#oferta-modal">Публичная оферта</a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="footer-block__item-social">
                        <a href="#" class="footer-block__item-social-link">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a href="#" class="footer-block__item-social-link">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a href="#" class="footer-block__item-social-link">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a href="#" class="footer-block__item-social-link">
                            <i class="fa fa-telegram" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
        <div id="oferta-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl text-justify">
                <div class="modal-content">
                    <div data-v-2f0f182a="" class="m-auto text-center pt-4 pb-0 oferta-text">
                        <h2 class="title-oferta">ОФЕРТА</h2>
                        <p><strong data-v-2f0f182a="">о предоставлении услуг электронного документооборота</strong></p>
                        <button data-v-2f0f182a="" type="button" data-dismiss="modal" aria-label="Close" class="close btn-close"><img data-v-2f0f182a="" src="/img/svg/close.svg" alt=""></button>
                    </div>
                    <div class="modal-body p-2">
                        <p class="oferta-text-indent"><span class="oferta-text-bold">OOO «PLUM TECHNOLOGIES»</span>, именуемое в дальнейшем «Оператор», в лице директора Абдуганиева А.И.,
                            действующего на основании Устава, во исполнение Законов Республики Узбекистан «Об электронной
                            коммерции», «Об электронной цифровой подписи», «О бухгалтерском учете», «Об электронном документообороте»;
                            постановлений Кабинета Министров Республики Узбекистан «О мерах по дальнейшему совершенствованию порядка
                            осуществления сделок в электронной коммерции» от 02.06.2016 г. № 185, «О мерах по улучшению использования
                            электронных счет-фактур в системе взаиморасчетов» от 25 июня 2019 г. №522, выражает готовность заключить
                            договор о предоставлении услуг электронного документооборота на следующих условиях:
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Клиент</span> – юридическое лицо или индивидуальный предприниматель,
                            заключивший договор о предоставлении услуг электронного документа посредством электронной цифровой подписи;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Личный кабинет</span> – сервис, предоставляемый Оператором своим Клиентам для управления услугами;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Сайт Системы</span> – ресурс Оператора в глобальной компьютерной сети Интернет,
                            на котором Оператор размещает информацию о собственных услугах, включая информацию о любых изменениях в договор,
                            действующих тарифах на услуги и любую иную информацию, раскрытие которой предусмотрено настоящей Офертой.
                            Постоянный адрес сайта Системы https://edo.uzcardtrade.uz/;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Система</span> – специально разработанная и сопровождаемая Оператором
                            информационная система, представляющая собой совокупность информационных ресурсов, информационных
                            технологий и средств связи, позволяющая осуществлять сбор, хранение, поиск, обработку и пользование
                            информацией, предназначенной для организации юридически значимого электронного документооборота
                            между юридическими лицами и/или индивидуальными предпринимателями, а также для предоставления сопутствующего сервиса;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Тарифный план</span> – документ, содержащий перечень возможных услуг и порядок определения
                            их стоимости, являющийся приложением к настоящей Оферте;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Электронный документ</span> – информация, зафиксированная в электронной форме,
                            подтвержденная электронной цифровой подписью и имеющая другие реквизиты электронного документа, позволяющие его идентифицировать;
                        </p>
                        <p class="oferta-text-indent">
                            <span class="oferta-text-bold">Электронный документооборот</span> – совокупность процессов отправки и получения электронных
                            документов посредством Системы Оператора.
                        </p>
                        <p class="oferta-text-indent">
                            1. Оператор подключает Клиента к принадлежащей Оператору на праве собственности Системе и оказывает услуги по организации
                            электронного документооборота, а Клиент принимает и обязуется оплачивать оказанные услуги в размерах и порядке, согласно
                            условиям настоящей Оферты и выбранного тарифного плана.
                        </p>
                        <p class="oferta-text-indent">
                            2. Клиенты признают электронные документы, подписанные электронной подписью, оформляемые посредством Системы, равнозначными
                            документу на бумажном носителе, подписанному собственноручной подписью и заверенному печатью, признают все юридические действия,
                            оформляемые посредством электронных документов, и договариваются не оспаривать законность и действительность указанных действий
                            только на том основании, что они совершены в электронном виде.
                        </p>
                        <p class="oferta-text-indent">
                            3. Клиенты признают применяемые Оператором средства криптографической защиты информации, программно-аппаратных и организационных
                            мер информационной безопасности, достаточными для обеспечения конфиденциальности и целостности информации и невозможности ее
                            фальсификации при организации электронного документооборота.
                        </p>
                        <p class="oferta-text-indent">
                            4. Клиент признает и подтверждает, что Оператор не несет ответственности за любые финансовые, юридические и другие последствия,
                            прямо или косвенно вытекающие из взаимоотношений Клиента и его контрагента посредством обмена электронными документами через
                            Оператора и содержания передаваемых Клиентом электронных документов.
                        </p>
                        <p class="oferta-text-indent">
                            5. Оператор не обязан контролировать или проверять достоверность передаваемых, получаемых и хранимых электронных документов
                            и электронных сообщений, а также их соответствие законодательству. Ответственность за достоверность и правильность данных в
                            электронных документах, представленных Оператору для передачи контрагентам Клиента, несет сам Клиент.
                        </p>
                        <p class="oferta-text-indent">
                            6. Клиент не может ни при каких обстоятельствах распространять и отправлять информацию, направленную на подрыв государственного,
                            конституционного и общественного строя, нарушение территориальной целостности, политической независимости и государственного
                            суверенитета Республики Узбекистан и иных государств, пропаганду войны, терроризма, насилия, национальной исключительности
                            и религиозной ненависти, расизма и его разновидностей (антисемитизм, фашизм), порочащую, честь и достоинство граждан
                            Республики Узбекистан, вмешательство в их личную жизнь, материалы порнографического содержания, любую информацию,
                            содержащую компьютерные вирусы или другие компоненты, приравненные к ним, а также совершение других действий,
                            влекущих за собой уголовную ответственность.
                        </p>
                        <p class="oferta-text-indent">
                            7. Предусмотренные настоящей Офертой услуги оказываются при условии оплаты Клиентом тарифов, предусмотренных Тарифным планом,
                            и наличия у Клиента программного средства пользователя Системы, реализующего функции, предусмотренные действующими нормативными
                            правовыми и иными актами, регулирующими порядок электронного документооборота в Системе и совместимого со средствами Оператора.
                        </p>
                        <p class="oferta-text-indent">
                            8. Клиент оплачивает услуги Оператора в порядке, в размере и на условиях, определенных тарифами, указанными в Тарифном плане.
                        </p>
                        <p class="oferta-text-indent">
                            9. Оплата Клиентом услуг Оператора по договору осуществляется ежемесячно на основании выставленного счета, а также подписанного
                            Сторонами Акта оказанных услуг за истекший календарный месяц, переданный по электронной почте, факсимильной связи, почтовым
                            отправлением или другим доступным Клиенту способом.
                        </p>
                        <p class="oferta-text-indent">
                            10. Датой оплаты считается дата поступления денежных средств на расчетный счет Оператора. Оператор зачисляет суммы оплаты Клиента
                            по хронологии возникновения обязательств Клиента перед Оператором.
                        </p>
                        <p class="oferta-text-indent">
                            11. Клиент вправе: <br>
                            <span class="oferta-text-padding">получать от Оператора услуги в соответствии с условиями настоящей Оферты;</span> <br>
                            <span class="oferta-text-padding">получать от Оператора консультации по вопросам, связанным с обменом электронных документов в Системе;</span><br>
                            пользоваться льготными (бонусными) услугами и средствами, полученными от Оператора в качестве поощрения за регистрацию или другие действия.
                        </p>
                        <p class="oferta-text-indent">
                            12. Оператор имеет право: <br>
                            <span class="oferta-text-padding">требовать от Клиента осуществления электронного документооборота с помощью Системы;</span> <br>
                            <span class="oferta-text-padding">инициировать расторжение договора в случае неисполнения или ненадлежащего исполнения Клиентом обязанностей, предусмотренных настоящей Офертой;</span> <br>
                            <span class="oferta-text-padding">определять условия оказания услуг и цены на свои услуги;</span> <br>
                            <span class="oferta-text-padding">привлекать третьих лиц к исполнению своих обязательств по договору только с согласия Клиента;</span> <br>
                            приостанавливать предоставление услуг Клиентам в случае нарушения своих обязательств по договору, наличия у Клиента непогашенной задолженности перед Оператором.
                        </p>
                        <p class="oferta-text-indent">
                            13. Оператор обязан: <br>
                            <span class="oferta-text-padding">соблюдать законодательство, в том числе стандарты, нормы и правила в области электронной коммерции;</span><br>
                            <span class="oferta-text-padding">раскрывать участникам электронной коммерции информацию о своем полном наименовании, организационно-правовой форме, оказываемых услугах, условиях их оказания и стоимости (тарифах);</span><br>
                            <span class="oferta-text-padding">не изменять содержания электронных документов и электронных сообщений, порядок их использования, за исключением случаев, предусмотренных законодательством Республики Узбекистан;</span><br>
                            <span class="oferta-text-padding">обеспечивать мерами защиты электронные документы, электронные сообщения и персональные данные от несанкционированного доступа;</span><br>
                            <span class="oferta-text-padding">не передавать третьим лицам электронные документы и электронные сообщения, за исключением случаев, предусмотренных законом;</span><br>
                            <span class="oferta-text-padding">обеспечивать хранение электронных документов и электронных сообщений в соответствии с законодательством;</span><br>
                            <span class="oferta-text-padding">размещать свою Систему на серверах, находящихся на территории Республики Узбекистан.</span>
                        </p>
                        <p class="oferta-text-indent">
                            14. Клиент обязуется: <br>
                            <span class="oferta-text-padding">оплачивать оказанные услуги согласно установленным тарифам;</span> <br>
                            <span class="oferta-text-padding">надлежащим образом выполнять условия настоящей Оферты;</span><br>
                            <span class="oferta-text-padding">устанавливать необходимые аппаратные средства в соответствии с требованиями к минимальным или рекомендуемым техническим характеристикам компьютера, а также поддерживать их в работоспособном состоянии;</span><br>
                            <span class="oferta-text-padding">использовать полученные от Оператора программно-технические средства только с целью осуществления электронного документооборота в Системе, не передавать их третьим лицам без письменного согласия Оператора;</span><br>
                            <span class="oferta-text-padding">исключить возможность проведения декомпиляции, модификации программного кода, не совершать относительно указанных программно-технических средств других действий, нарушающих действующее законодательство;</span><br>
                            <span class="oferta-text-padding">не совершать действий, способных привести к нарушению целостности Системы, а также незамедлительно сообщать Оператору о ставших известными Клиенту попытках третьих лиц совершить действия, направленные на нарушение целостности Системы;</span><br>
                            <span class="oferta-text-padding">самостоятельно обеспечивать доступ через сеть общего пользования Интернет к телекоммуникационным ресурсам Оператора, используемым в Системе;</span><br>
                            <span class="oferta-text-padding">самостоятельно (или через уполномоченных лиц) не реже одного раза в неделю:</span><br>
                            <span class="oferta-text-padding">- обращаться на сайт Оператора для своевременного ознакомления с изменениями, произведенными в составе и условиях предоставления услуг (в том числе финансовых);</span><br>
                            <span class="oferta-text-padding">- заходить в свой личный кабинет на сайте Оператора для своевременного получения информации по договору от Оператора;</span><br>
                            <span class="oferta-text-padding">- осуществлять прием информации с помощью программных средств пользователя Системы для своевременного получения электронных документов Оператора, других участников Системы, формируемых в рамках настоящей Оферты;</span><br>
                            <span class="oferta-text-padding">- не передавать свои права и обязанности по договору третьей стороне без согласия Оператора.</span><br>
                        </p>
                        <p class="oferta-text-indent">
                            15. За неисполнение и/или ненадлежащее исполнение обязательств по договору Стороны несут ответственность в соответствии с действующим законодательством Республики Узбекистан.
                        </p>
                        <p class="oferta-text-indent">
                            16. В случае просрочки исполнения Стороной обязательств, предусмотренных договором, а также в иных случаях ненадлежащего исполнения обязательств,
                            вторая Сторона вправе потребовать уплаты неустоек (штрафов, пеней). Пеня начисляется за каждый день просрочки
                            исполнения обязательства, предусмотренного договором, начиная со дня, следующего после дня истечения установленного договором срока исполнения обязательства.
                        </p>
                        <p class="oferta-text-indent">
                            17. Уплата неустойки не освобождает Стороны от исполнения своих обязательств по договору и от возмещения убытков, причиненных неисполнением или
                            ненадлежащим исполнением своих обязательств.
                        </p>
                        <p class="oferta-text-indent">
                            18. Виновная сторона освобождается от уплаты неустойки (пени, штрафа), если докажет, что просрочка исполнения указанного обязательства произошла
                            вследствие непреодолимой силы или по вине другой Стороны.
                        </p>
                        <p class="oferta-text-indent">
                            19. Затронутая форс-мажорными обстоятельствами Сторона информирует другую Сторону об этих обстоятельствах и об их последствиях в течение 5 (пяти) рабочих дней с
                            момента наступления форс-мажорных обстоятельств и принимает все возможные меры с целью минимизации отрицательных последствий, вызванных указанными форс-мажорными обстоятельствами.
                        </p>
                        <p class="oferta-text-indent">
                            20. В случае отсутствия технической возможности направить уведомление об обстоятельствах непреодолимой силы в результате действия таких обстоятельств,
                            Сторона, затронутая обстоятельствами непреодолимой силы, обязана сообщить о них незамедлительно, как только это будет возможно.
                        </p>
                        <p class="oferta-text-indent">
                            21. Несвоевременное извещение Стороны другой Стороной, для которой была создана невозможность выполнения обязательств по договору, о наступлении форс-мажорных обстоятельств,
                            влечет за собой утрату права ссылаться на такие обстоятельства как на основании освобождения от ответственности.
                        </p>
                        <p class="oferta-text-indent">
                            22. Под конфиденциальной информацией подразумевается любая информация и данные, письменные или устные, включающие, помимо прочего, деловую, коммерческую, в том числе информацию
                            о налоговой, бухгалтерской и иной отчетности, о ценах и объемах продаж, исследовательскую, производственную информацию, информацию о разработках, деятельности,
                            результативности работы, процессах, продуктах и любую иную коммерческую и техническую информацию, ноу-хау, образцы, модели, аппаратуру (при наличии таковой) и
                            все носители данных, содержащие или раскрывающие такую информацию и технологии, которые разглашаются Сторонами друг другу в соответствии с настоящей Офертой.
                        </p>
                        <p class="oferta-text-indent">
                            23. Конфиденциальная информация, которой обмениваются Стороны в соответствии с договором, не распределяется, не раскрывается или не распространяется каким-либо
                            способом или в какой-либо форме получающей стороной кому-либо, кроме своих собственных служащих, и привлекаемых для исполнения обязательств, предусмотренных договором
                            и приложением к нему, третьих лиц, у которых есть обоснованная необходимость знать вышеуказанную информацию.
                        </p>
                        <p class="oferta-text-indent">
                            24. Обязательства, предусмотренные п.22 не применимы к любой информации, которая: уже находится в общедоступной сфере или становится доступной общественности без
                            нарушения получающей стороной; правомерно находилась в распоряжении получающей стороны без обязательства о неразглашении конфиденциальной информации до ее получения
                            от раскрывающей стороны, что подтверждается письменными документами получающей стороны; согласно имеющимся доказательствам правомерно получена от третьей стороны
                            без обязательства о неразглашении конфиденциальной информации; одобрена для обнародования путем письменного согласия раскрывающей стороны; подлежит обязательному
                            соглашению согласно нормам действующего законодательства Республики Узбекистан.
                        </p>
                        <p class="oferta-text-indent">
                            25. Все споры, разногласия или требования, претензии, конфликтные и спорные ситуации, которые могут возникнуть при исполнении договора, заключенного по результатам
                            акцепта настоящей Оферты, разрешаются Сторонами путем переговоров.
                        </p>
                        <p class="oferta-text-indent">
                            26. В случае невозможности решения спора или преодоления разногласий путем переговоров, спор разрешается в Ташкентском межрайонном экономическом суде.
                        </p>
                        <p class="oferta-text-indent">
                            27. Любая из Сторон вправе расторгнуть договор в одностороннем порядке, известив об этом другую Сторону в письменном виде не позднее чем за 15 (пятнадцать)
                            дней до предполагаемой даты расторжения договора. Стороны осуществляют окончательные взаиморасчеты в течение 10 (Десяти) дней с даты расторжения договора.
                        </p>
                        <p class="oferta-text-indent">
                            28. Оператор вправе в одностороннем порядке отказаться от исполнения договора без предусмотренного п.27 предварительного оповещения Клиента в случае нарушения
                            Клиентом положений абзацы 1 и 7 п.14 настоящей Оферты, направив Клиенту в разумный срок соответствующее уведомление.
                        </p>
                        <p class="oferta-text-indent">
                            29. Изменения и/или дополнения, вносимые в локальные акты Оператора в связи с изменением норм законодательства, вступают в силу одновременно с вступлением в
                            силу изменений в указанных нормах.
                        </p>
                        <p class="oferta-text-indent">
                            30. Изменения и/или дополнения, вносимые Оператором в свои локальные акты в связи с расширением состава услуг, изменением терминологии, изменением структурного
                            содержания официальных документов Оператора и другими подобными изменениями, вступают в силу после опубликования соответствующих документов на сайте Оператора,
                            с даты, указанной Оператором.
                        </p>
                        <p class="oferta-text-indent">
                            31. В случае несогласия Клиента с изменениями и/или дополнениями, внесенными в договор или в тарифный план Оператора, а также с составом и условиями предоставления
                            услуг (в том числе финансовыми), определенными локальными актами Оператора, Клиент сообщает об этом Оператору в письменном виде в течение 7 (семи) дней с момента
                            раскрытия информации, но не позднее завершения периода оказания услуг, в котором изменения вступили в силу, с обязательным предварительным уведомлением Оператора
                            по телефону, факсимильной связи или электронной почте. В этом случае договор с Клиентом расторгается.
                        </p>
                        <p class="oferta-text-indent">
                            32. Акцептовав настоящую Оферту с использованием электронной цифровой подписи, Клиент подтверждает, что он ознакомлен и согласен с условиями настоящей Оферты,
                            содержащей существенные условия заключаемого договора, Тарифного плана и обязуется их соблюдать.
                        </p>
                        <p class="mt-3">
                            <span class="oferta-text-bold">Оператор</span> <br>
                            <span class="oferta-text-bold">ООО «PLUM TECHNOLOGIES»</span> <br>

                            Юридический адрес: 100027, г. Ташкент, ул. Абдулла Кодырий, д.78 <br>
                            тел.: (71) 238-69-76 <br>
                            Р/с 20208000300747061001 <br>
                            в АКБ «Капиталбанк», <br>
                            МФО 01088, ИНН 304 739 262, <br>
                            ОКЭД 63110 <br>

                            __________________/ Абдуганиев А.И./

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>