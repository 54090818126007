import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=6b18a55d&scoped=true&"
import script from "./AdminLayout.vue?vue&type=script&lang=js&"
export * from "./AdminLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b18a55d",
  null
  
)

export default component.exports