<template>
  <div>
    <router-view/>
    <vue-snotify/>
    <!--  Telegram  -->
    <a :href="`https://t.me/${$store.state.telegramUserName}`" id="telegram-btn" target="_blank" class="telegram-btn">
      <img src="../public/img/svg/telegram.svg" alt="">
      <span>Задать вопрос в телеграм</span>
    </a>
  </div>
</template>

<script>
import Localization from "./mixins/Localization";

export default {
  name: 'app',
  mixins: [Localization],
  created() {
    this.getLanguages();
    this.getLocalizationValues()
  },
  mounted() {
    this.$application.api = this.$api
  },
  methods: {}
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
