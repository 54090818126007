function toNumberFormatStyle2(number, pow = 10){
    if(number == null){
        return 0;
    }
    let numberString = number.toString();
    let index = numberString.search(/\./);
    if(index === -1){
        index = index === -1 ? numberString.length : numberString.length-1;
    }

    let whole = numberString.substring(0, index);
    let del = numberString.substring(index);

    del = del.substring(1, pow+1);
    del = removeNumbers(del, pow);

    let hasDelNumber = del.length > 0;
    let deFormat =[];

    for(let i=whole.length; i>0; i-=3){
        let start = i - 3;
        deFormat.unshift(whole.substring(start, start+3));
    }

    return deFormat.join(' ') + (hasDelNumber ? ',' + del : '');
}

function removeNumbers(number, pow){
    if(number.length < pow){
        return number;
    }

    number = number.toString().substring(0, pow);
    let count = pow;
    while(number.lastIndexOf('0') === number.length-1 && count > 0){
        number = number.substring(0, number.length-2);
        count--;
    }
    return number;
}


export default {
    methods: {
        numeralFormat(val, pow = 10){
            return toNumberFormatStyle2(val, pow);
            /*let minusIndex = val ? val.toString().indexOf('-'): -1;
            if(minusIndex === 0){
                if(val.length === 1){
                    return val;
                }

                val = val.toString().substring(1) *1;
            }

            if(!val || isNaN(val)){
                if(minusIndex === 0){
                    val = "-";
                }else{
                    val = 0;
                }
            }

            let delimiterIndex = val ? val.toString().indexOf('.'): -1;
            if(delimiterIndex!== -1 &&delimiterIndex < val.toString().length-3){
                if(isNaN(val)){
                    return ''
                }
                try{
                    val = val ? val.substring(0, delimiterIndex+3): 0

                }catch (e) {
                    return ''
                }
            }

            let val_str = !isNaN(val) ? this.toNumberFormatStyle(val): 0;
            if(delimiterIndex !== -1 && delimiterIndex === val.length -1){
                val_str += ","
            }

            if(delimiterIndex !== -1 && delimiterIndex === val.length - 2 && val.toString().indexOf('0') === val.length - 1){
                val_str += ",0"
            }

            if(minusIndex === 0){
                val_str = "-" + val_str;
            }

            return val_str*/
        },
        numeralFormatToNumber(val){
            if (val.length === 1) {
                if(val.indexOf('-') === 0){
                    return '-'
                }
            }
            return (val ? val.replace(/\s/g, "").replace(",", "."): val)*1
        },
        // numeralFormatToNumber(val){
        //     if(val.indexOf('-') === 0 && val.length === 1){
        //         return '-'
        //     }
        //     return (val ? val.replace(/\s/g, "").replace(",", "."): val)*1
        // },
        toNumberFormatStyle(number){
            let numberString = number.toString();
            let index = numberString.search(/\./);

            let hasDelNumber = true;
            if(index === -1 || index === numberString.length - 1){
                hasDelNumber = false;
                index = index === -1 ? numberString.length : numberString.length-1;
            }

            let whole = numberString.substring(0, index);
            let del = numberString.substring(index);
            let deFormat =[];
            for(let i=whole.length; i>0; i-=3){
                let start = i - 3;
                deFormat.unshift(whole.substring(start, start+3));
            }

            return deFormat.join(' ') + (hasDelNumber ? ',' + del.substring(1) : '');
        },
        toNumberFormatStyle2(number, pow = 10){
            let numberString = number.toString();
            let index = numberString.search(/\./);

            let hasDelNumber = true;
            if(index === -1){
                hasDelNumber = false;
                index = index === -1 ? numberString.length : numberString.length-1;
            }

            let whole = numberString.substring(0, index);
            let del = numberString.substring(index);

            del = del/pow*pow;
            pow = del.length-1 > pow ? pow : del.length - 1;

            let deFormat =[];
            for(let i=whole.length; i>0; i-=3){
                let start = i - 3;
                deFormat.unshift(whole.substring(start, start+3));
            }

            return deFormat.join(' ') + (hasDelNumber ? ',' + del.substring(1, pow+1) : '');
        },
        fixNumber(val){
            if(val.length <= 3){
                return val;
            }
            let len = val.length;

            if(len > 5){
                return val;
            }

            let count9 = this.getRepeatNumberCount(val, 9);
            let count0 = this.getRepeatNumberCount(val, 0);

            if(count9 != null && count9.val/val > .6 ){
                val = val.substring(0, count9.index) * 1;
                val++;
            }else if(count0 != null && count0/val > .6){
                val = val.substring(0, count0.index) * 1;
            }

            return val;
        },
        getRepeatNumberCount(val,searchVal){
            let count = 0;
            let counts = [];
            for(let i=0; i < val.length; i++){
                let index = val.toString().search(searchVal);
                if(index === -1){
                    counts.push({val: count, index: i});
                    count =0;
                }else{
                    count++;
                }
            }

            return this.getMax(counts);
        },
        getMax(numbers){
            let len = numbers.length;
            if(numbers.length === 0){
                return null;
            }
            let maxVal = numbers[i];
            for(let x in numbers){
                if(maxVal.val < numbers[x].val){
                    maxVal = numbers[x];
                }
            }

            return maxVal;
        }
    },
    filters:{
        numeralFormatFilter(val){
            return  !isNaN(val) ? toNumberFormatStyle2(val): 0
        }
    }
}
