<template>
    <div class="header-top-view">
        <section id="theader_id" class="l-header_top">
            <div class="my_container">
                <div class="l-header_tall">
                    <a class="l-header_tall_logod" href="/">
                        <img src="/img/landing-page/img/EDO-lofo.svg">
                    </a>
                    <div class="l-header_tall_right">
                        <div class="tel-number">
                            <span>Номер телефона:</span>
                            <a href="tel: +998781508855">(78) 150 88 55</a>
                        </div>
                        <div v-if="!isAuth" class="login-btn__block">
	                        <router-link :to="{name: 'dashboard'}" class="login-btn">
		                        Войти в кабинет
	                        </router-link>

                        </div>
                        <div v-if="isAuth" class="my_user">
	                        <div>
	                            <div  class="my_user_img_cover">
	                                <img class="my_user_img" v-bind:src="img == null ? '/img/avatar.jpg' : '/img/landing-page/img/user-img.png'">
	                            </div>
	                        </div>
                            <div class="my_user_down">
                                <div class="trankle_fordown"></div>
	                            <router-link :to="{name: 'dashboard'}" tag="button" class="btn pr_btn">
	                                Кабинет
	                            </router-link>
	                            <button  @click="logout" class="btn pr_btn">Выйти</button>
                            </div>
                        </div>
                        <!--<div class="selct_un">
                            <select>
                                <option value="">Ру</option>
                                <option value="">En</option>
                                <option value="">Uz</option>
                            </select>
                            <span class="selct_un_down">
								<img src="../../../../../public/img/landing-page/img/icons/downk.png">
							</span>
                        </div>-->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import TokenService from "../../services/TokenService";

    export default {
        name: "HeaderTop",
        data() {
            return {
                img: null,
                user: false
            }
        },
	    methods:{
            logout() {
                localStorage.clear();
                this.$router.go()
            },
	    },
	    computed:{
            isAuth(){
                return TokenService.getToken() && TokenService.getToken().length>0
            }
	    }
    }
</script>

<style scoped>
.login-btn{
	padding: 5px 10px;
	background: #00448a;
	color: #fff;
}
	.login-btn:hover{
		text-decoration: none;
		font-weight: bold;
		opacity: .8;
	}
</style>